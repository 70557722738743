import { FC, useCallback, useRef, useState } from 'react';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/kit/close.svg';
import { useClickOutside } from 'src/shared/lib/hooks/useClickOutside';
import { Select, SelectItemType } from 'src/shared/ui';

import { DatePickerCell } from './components/DatePickerCell';
import { useCalendar } from './hooks/useDatePicker';
import { checkDateEqual } from './utils';

export type DatePickerProps = {
  onClose?: () => void;
  markedDate?: Date;
  locale?: string;
  onClick?: (date: Date) => void;
  classNames?: string;
};

export const DatePicker: FC<DatePickerProps> = ({
  onClose,
  markedDate,
  locale = 'default',
  onClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(markedDate);
  const { state, functions } = useCalendar({ date: markedDate, locale });

  const monthOptions = state.monthsNames.map((month, i) => ({ label: month, value: i.toString() }));
  const yearOptions = state.yearsNames.map(year => ({
    label: year.toString(),
    value: year.toString(),
  }));

  const selectedMonth = state.selectedMonth.monthIndex.toString();
  const selectedYear = state.selectedMonth.year.toString();

  const handleClick = useCallback(
    (date: Date) => {
      if (typeof onClick === 'function') {
        setSelectedDate(date);
        onClick(date);
      }
    },
    [onClick],
  );

  const handleChangeYear = ({ value }: SelectItemType) => functions.changeYear(+value);
  const handleChangeMonth = ({ value }: SelectItemType) => functions.changeMonth(+value);

  const handleClose = () => {
    onClose && onClose();
  };

  useClickOutside(ref, handleClose);

  return (
    <div
      className="relative rounded-lg bg-blue-1200 p-5 shadow-blue-2xl dark:bg-violet-1100"
      ref={ref}
    >
      {onClose && (
        <div
          className="group absolute right-4 top-4 flex h-5 w-5 cursor-pointer items-center justify-center"
          onClick={handleClose}
        >
          <CloseIcon className="h-4 w-4 stroke-white" />
        </div>
      )}

      <div className="font-gothampro-400 mb-4 text-sm dark:text-white">Выберите дату</div>

      <div className="mb-2.5 flex">
        <Select
          options={monthOptions}
          value={selectedMonth}
          onChange={handleChangeMonth}
          classNames="w-32"
          noToolTip
        />
        <Select
          options={yearOptions}
          value={selectedYear}
          onChange={handleChangeYear}
          classNames="w-24 ml-auto"
          
          noToolTip
        />
      </div>
      <div className="grid grid-cols-7 gap-x-2">
        {state.weekDaysNames.map((name, i) => (
          <DatePickerCell key={`week-day-${i}` as string} weekDay={name} />
        ))}
      </div>
      <div className="grid grid-cols-7 gap-x-1">
        {state.calendarDays.map((day, i) =>
          day ? (
            <DatePickerCell
              onClick={() => handleClick(day?.date)}
              key={`month-day-${i}` as string}
              dayNumber={day.dayNumber}
              {...(selectedDate && { isMarkedDate: checkDateEqual(selectedDate, day.date) })}
            />
          ) : (
            <div key={`month-day-${i}` as string} />
          ),
        )}
      </div>
    </div>
  );
};
