import { useState } from 'react';

type SetValueAction<T> = T | ((prev: T) => T);

interface UseUndoableStateOptions<T> {
  initialValue: T;
  maxHistory?: number;
}

interface UseUndoableStateReturn<T> {
  value: T;
  setValue: (action: SetValueAction<T>) => void;
  undo: () => void;
  canUndo: boolean;
}

export function useUndoableState<T>({
  initialValue,
  maxHistory = 5
}: UseUndoableStateOptions<T>): UseUndoableStateReturn<T> {
  const [value, setValueState] = useState<T>(initialValue);
  const [history, setHistory] = useState<T[]>([]);

  const setValue = (action: SetValueAction<T>) => {
    setHistory((prev) => {
      // Определяем новое значение
      const newValue = typeof action === 'function' ? (action as (prev: T) => T)(value) : action;
      const newHistory = [...prev, value];
      if (newHistory.length > maxHistory) {
        newHistory.shift();
      }
      setValueState(newValue);
      return newHistory;
    });
  };

  const undo = () => {
    setHistory((prev) => {
      if (prev.length === 0) return prev;
      const previousValue = prev[prev.length - 1];
      setValueState(previousValue);
      return prev.slice(0, -1);
    });
  };

  return {
    value,
    setValue,
    undo,
    canUndo: history.length > 0
  };
}
