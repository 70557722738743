import { DayType, MonthType } from './types';

export const checkDateEqual = (date1: Date, date2: Date): boolean =>
  date1.getDate() === date2.getDate() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getFullYear() === date2.getFullYear();

export const checkDateToday = (date: Date): boolean => {
  const today = new Date();

  return checkDateEqual(today, date);
};

export type CreateDateParams = {
  date?: Date;
  locale?: string;
};

export const createDate = (params?: CreateDateParams): DayType => {
  const d = params?.date ?? new Date();
  const locale = params?.locale ?? 'default';

  const dayNumber = d.getDate();
  const dayNumberInWeek = d.getDay() + 1;
  const year = d.getFullYear();
  const monthIndex = d.getMonth();
  const timestamp = d.getTime();
  const monthName = d.toLocaleDateString(locale, { month: 'long' });

  return {
    date: d,
    dayNumber,
    dayNumberInWeek,
    year,
    monthIndex,
    timestamp,
    monthName,
  };
};

export type CreateMonthParams = {
  date?: Date;
  locale?: string;
};

export const createMonth = (params?: CreateMonthParams): MonthType => {
  const date = params?.date ?? new Date();
  const locale = params?.locale ?? 'default';

  const { year, monthIndex, monthName } = createDate({ date, locale });

  const getDay = (dayNumber: number) =>
    createDate({ date: new Date(year, monthIndex, dayNumber), locale });

  const days: DayType[] = [];

  for (let i = 0; i <= new Date(year, monthIndex + 1, 0).getDate() - 1; i += 1) {
    days[i] = getDay(i + 1);
  }
  return { days, monthIndex, year, monthName };
};

export const getMonthName = (locale = 'default') =>
  Array.from({ length: 12 }).map((_, i) =>
    new Date(2000, i, 1).toLocaleString(locale, {
      month: 'long',
    }),
  );

  export const getYearName = (startYear = 1900) => {
    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 30; // 30 лет вперед
    const years: number[] = [];
  
    for (let i = endYear; i >= startYear; i--) {
      years.push(i);
    }
  
    return years;
  };
  
  
export const getWeekDayName = (locale = 'default'): string[] => {
  const weekDaysNames: string[] = Array.from({ length: 7 });
  const d = new Date();

  weekDaysNames.forEach((_, i) => {
    const date = new Date(d.getFullYear(), d.getMonth(), d.getDate() + i);
    const dayShort = date.toLocaleDateString(locale, { weekday: 'short' });
    const dayNumberInWeek = date.getDay() + 1;
    weekDaysNames[dayNumberInWeek - 1] = dayShort;
  });

  return [...weekDaysNames.slice(1), ...weekDaysNames.slice(0, 1)];
};
