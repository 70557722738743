import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';
import { ReactComponent as ErrorIcon } from 'src/shared/assets/icons/kit/error.svg';
import { useClickOutside } from 'src/shared/lib/hooks/useClickOutside';
import { ToolTip } from 'src/shared/ui';

export type SelectItemType = {
  label: string;
  value: string;
  icon?: ReactNode;
};

interface SelectProps {
  onChange?: (value: SelectItemType) => void;
  options: SelectItemType[];
  error?: string;
  value?: string;
  disabled?: boolean;
  noToolTip?: boolean;
  classNames?: string;
}

export const Select: FC<SelectProps> = ({
  classNames,
  disabled,
  error,
  options,
  onChange,
  noToolTip,
  value,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<SelectItemType | undefined>(
    options.find(option => option.value === value),
  );
  const ref = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<(HTMLDivElement | null)[]>([]); // Для отслеживания всех опций

  useEffect(() => {
    setSelectValue(options.find(option => option.value === value));
  }, [value]);

  useEffect(() => {
    if (isOpen && selectValue) {
      const index = options.findIndex(option => option.value === selectValue.value);
      if (index !== -1 && optionsRef.current[index]) {
        optionsRef.current[index]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [isOpen, selectValue, options]);

  useClickOutside(ref, () => {
    setIsOpen(false);
  });

  const handleOpen = () => {
    if (disabled) return;

    setIsOpen(prev => !prev);
  };

  const handleSelect = (item: SelectItemType) => {
    onChange && onChange(item);
    setSelectValue(item);
    setIsOpen(false);
  };

  return (
    <div
      className={cn(
        {
          'w-full': !classNames?.includes('w-'),
        },
        'relative h-11',
        classNames,
      )}
      ref={ref}
    >
      <div
        className={cn(
          {
            'rounded-b hover:bg-grey-1300 dark:hover:border-violet-900': !isOpen,
            'dark:border-violet-900 dark:bg-violet-1200': isOpen,
            'bg-grey-1200 dark:bg-violet-1200':
              (!selectValue && !isOpen) || (selectValue && !isOpen),
          },
          'flex h-full w-full cursor-pointer items-center rounded-t border p-4',
          'border-violet-400 dark:border-violet-900 dark:hover:bg-violet-1400',
        )}
        onClick={handleOpen}
      >
        {noToolTip ? (
          <div className="font-gothampro-400 mr-auto truncate whitespace-nowrap dark:text-white">
            {selectValue?.label}
          </div>
        ) : (
          <ToolTip label={selectValue?.label}>
            <div className="font-gothampro-400 mr-auto truncate whitespace-nowrap dark:text-white">
              {selectValue?.label}
            </div>
          </ToolTip>
        )}
        {selectValue?.icon && <div className="">{selectValue.icon}</div>}
        <ArrowSmallIcon
          className={cn({ '-rotate-90': isOpen }, 'ml-2 h-3 w-3 min-w-[0.75rem] dark:stroke-white')}
        />
      </div>

      {isOpen && (
        <div
          className={
            'absolute left-0 top-full z-10 max-h-40 w-full overflow-auto rounded-b ' +
            'bg-grey-1100 shadow-2xl dark:bg-violet-1200'
          }
        >
          {options.map((option, index) => (
            <div
              key={`option-${option.value}`}
              ref={el => (optionsRef.current[index] = el)}
              onClick={() => handleSelect(option)}
              className={cn(
                {
                  'bg-grey-1700 dark:bg-violet-1400 dark:text-white':
                    selectValue?.value === option.value,
                  'dark:text-grey-900 dark:hover:text-white': selectValue?.value !== option.value,
                },
                'flex h-10 w-full cursor-pointer items-center px-4 dark:bg-violet-1200',
                'bg-grey-1100 hover:bg-grey-1300 dark:hover:bg-violet-1400',
              )}
            >
              {noToolTip ? (
                <div className="font-gothampro-400 truncate whitespace-nowrap">{option.label}</div>
              ) : (
                <ToolTip label={option.label}>
                  <div className="font-gothampro-400 truncate whitespace-nowrap">
                    {option.label}
                  </div>
                </ToolTip>
              )}

              {option?.icon && (
                <div className="pointer-events-none ml-auto mr-2">{option.icon}</div>
              )}
            </div>
          ))}
        </div>
      )}
      {error && (
        <div className="absolute -bottom-4 flex">
          <ErrorIcon className="mr-1 h-2.5 w-2.5 fill-pink-100" />
          <div className="font-gothampro-400 text-extra-xs text-pink-100">{error}</div>
        </div>
      )}
    </div>
  );
};
