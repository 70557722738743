// src/App.tsx

import React, { FC, useContext, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { MainContainer, ProvidersContainer } from 'src/app/containers';
import { protectedRoutes, publicRoutes } from 'src/app/routes';
import { ProtectedRoute } from 'src/app/routes/ProtectedRoute';
import { PublicRoute } from 'src/app/routes/PublicRoute';
import { routesEnum } from 'src/shared/const';
import { TableContext } from 'src/app/providers/TableProvider';

export const App: FC = () => {
  const { undo, canUndo } = useContext(TableContext);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const ctrlKeyPressed = isMac ? e.metaKey : e.ctrlKey;

      if (ctrlKeyPressed && e.key === 'z') {
        e.preventDefault();
        if (canUndo) {
          undo();
        }
      }
    };

    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [undo, canUndo]);

  return (
    <BrowserRouter>
      <ProvidersContainer>
        <MainContainer>
          <Routes>
            {publicRoutes.map(route => (
              <Route
                key={`route-${route.path}`}
                path={route.path}
                element={<PublicRoute>{route.element}</PublicRoute>}
              />
            ))}

            {protectedRoutes.map(route => (
              <Route
                key={`protected-route-${route.path}`}
                path={route.path}
                element={<ProtectedRoute>{route.element}</ProtectedRoute>}
              />
            ))}

            <Route path="*" element={<Navigate to={routesEnum.LOGIN} />} />
          </Routes>
        </MainContainer>
      </ProvidersContainer>
    </BrowserRouter>
  );
};
