import { AxiosResponse } from 'axios';
import { ApiProjects } from 'src/shared/api/ApiProject';
import { PROJECTS_URL } from 'src/shared/config';
import { BatchRowsData, BatchRowsParams, BatchRowsRes } from './types';
import TokenService from '../token/TokenService';

const batchRows = (params: BatchRowsParams) =>
  ApiProjects.post<string, Promise<AxiosResponse<BatchRowsRes>>, BatchRowsData>(
    `/table/${params.table_id}/batch`,
    params.data,
  );

const importExcel = (tableId: number, params: FormData) =>
  ApiProjects.post<string, string, FormData>(`/table/${tableId}/parse`, params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  const exportExcel = (tableId: number) => {
    const url = PROJECTS_URL+`table/${tableId}/export`;
    const accessToken = TokenService.getAccessToken();
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`, // Замените на ваш токен
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(`Failed to export: ${response.status}`);
      }
      return response.blob(); // Возвращаем файл в формате Blob
    });
  };
  
  
  

const TableService = { batchRows, importExcel, exportExcel };

export default TableService;
