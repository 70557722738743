import { FC } from 'react';
import cn from 'classnames';
import { ReactComponent as LogoIcon } from 'src/shared/assets/icons/logo.svg';

interface OfertaProps {
  classNames?: string;
}

export const Oferta: FC<OfertaProps> = ({ classNames }) => {
  return (
    <div className={cn('flex w-full items-top justify-between', classNames)}>
      <div className="flex items-center">
        <LogoIcon className="mr-1 h-7 w-7" />
        <div className="font-gothampro-400 text-extra-xs">
          Copyright © Win Solutions 2024
        </div>
      </div>

      <div className="font-gothampro-400 text-extra-xs underline">Политика конфиденциальности</div>
    </div>
  );
};
