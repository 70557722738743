// src/app/containers/Table.tsx

import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CellChange, CellLocation, ReactGrid, Row, TextCell } from '@silevis/reactgrid';
import { TableContext } from 'src/app/providers/TableProvider';
import {
  ActionsCellTemplate,
  DateCellTemplate,
  DropdownCellTemplate,
  FileCellTemplate,
  HeaderCellTemplate,
  MyHeaderCell,
  NumberCellTemplate,
  TextCellTemplate,
} from 'src/entity/CellTemplates';
import { ProjectImportDataModal } from 'src/feature/ProjectImportDataModal';
import { ProjectSettingsModal } from 'src/feature/ProjectSettingsModal';
import { TableSettingsForm } from 'src/shared/store/projects/types';
import { UserContext } from 'src/shared/store/user';
import { CellTemplates as MyCellTemplates } from 'src/shared/types';
import { Loader } from 'src/shared/ui/Loader';

import {
  useCheckAddRow,
  useCheckPasteAddRow,
  useColumns,
  useContextMenu,
  useInfinityScroll,
} from '../hooks';
import { applyChanges, getHeaderRow, getRows } from '../utils';

import '@silevis/reactgrid/styles.css';
import '../styles/styles.css';

interface TableProps {
  tableSettings: TableSettingsForm;
  height: string;
  width: string;
  setFullWidth: Dispatch<SetStateAction<boolean>>;
}

export const Table: FC<TableProps> = ({ height, width, tableSettings, setFullWidth }) => {
  const { tableData, setTableData, setSort, isLoading, isEdit } = useContext(TableContext);
  const { user } = useContext(UserContext);

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenImportModal, setIsOpenImportModal] = useState(false);
  const [isIdHidden, setIsIdHidden] = useState<boolean>(true);
  const [headerRows, setHeaderRows] = useState<Row<MyHeaderCell>>(getHeaderRow(tableSettings));

  const memoTableSettings = useMemo(() => {
    if (!isIdHidden) {
      setHeaderRows(getHeaderRow(tableSettings));
      return tableSettings;
    }

    const settings = {
      ...tableSettings,
      formStructure: tableSettings.formStructure.filter(
        ({ columnName }) => columnName.toLowerCase() !== 'id',
      ),
    };

    setHeaderRows(getHeaderRow(settings));
    return settings;
  }, [isIdHidden]);

  useEffect(() => {
    const contextMenu = document.querySelector('.rg-context-menu') as HTMLDivElement;

    if (contextMenu && contextMenu.style.visibility === 'visible') {
      contextMenu.style.visibility = 'hidden';
    }
  }, []);

  useCheckAddRow(setFullWidth);
  useInfinityScroll();

  const { simpleHandleContextMenu } = useContextMenu({
    isIdHidden,
    setIsIdHidden,
    setIsOpenEditModal,
    setIsOpenImportModal,
  });
  const { setSelectedRowId } = useCheckPasteAddRow();
  const { columns, handleColumnResize } = useColumns(memoTableSettings, width, isIdHidden);
  const isDeveloper = user?.isDeveloper;

  const handleChanges = (changes: CellChange<TextCell>[]) => {
    const updatedData = applyChanges(changes, tableData, setSort, setHeaderRows);
    // Здесь мы вызываем setTableData, что триггерит сохранение состояния в хук useUndoableState
    setTableData(updatedData);
  };

  const handleFocusLocationChanged = (location: CellLocation) => {
    setSelectedRowId(location.rowId);
  };

  const myCellTemplates: MyCellTemplates = {
    date: new DateCellTemplate(),
    header: new HeaderCellTemplate(),
    file: new FileCellTemplate(),
    actions: new ActionsCellTemplate(),
    floatNumber: new NumberCellTemplate(),
    dropdown: new DropdownCellTemplate(),
    number: new NumberCellTemplate(),
    text: new TextCellTemplate(),
  };

  return (
    <div
      id="table-container"
      className="grid-table relative overflow-x-auto overflow-y-auto"
      style={{ height, width }}
    >
      {isLoading && (
        <div className="absolute inset-0 z-10 flex items-center justify-center bg-blue-rgba-100">
          <Loader classNames="w-32 h-32" />
        </div>
      )}

      <ReactGrid
        rows={getRows(tableData, headerRows, memoTableSettings?.formStructure || [], isEdit)}
        columns={columns}
        onColumnResized={handleColumnResize}
        onCellsChanged={handleChanges}
        onFocusLocationChanged={handleFocusLocationChanged}
        onContextMenu={simpleHandleContextMenu}
        customCellTemplates={myCellTemplates}
        stickyTopRows={1}
        enableFillHandle={!isDeveloper}
        enableRangeSelection={!isDeveloper}
      />

      {isOpenEditModal && <ProjectSettingsModal setIsOpenEditModal={setIsOpenEditModal} />}
      {isOpenImportModal && <ProjectImportDataModal onClose={() => setIsOpenImportModal(false)} />}
    </div>
  );
};
