import React, { useState, useContext, Dispatch, SetStateAction, FC } from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { UserContext } from 'src/app/providers/UserProvider';
import { ReactComponent as LoupeIcon } from 'src/shared/assets/icons/kit/loupe.svg';
import { ReactComponent as UserIcon } from 'src/shared/assets/icons/kit/user-outline.svg';
import { ReactComponent as LogoIcon } from 'src/shared/assets/icons/logo.svg';
import { debounce } from 'src/shared/lib/utils';
import { Button } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

import { ProjectListItem } from '../components/ProjectListItem';

interface ProjectsAndFormsListProps {
  setIsSmall: Dispatch<SetStateAction<boolean>>;
  handleOpenCreateProjectModal: () => void;
  handleOpenFormModal: () => void;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  setWidth: Dispatch<SetStateAction<number>>; // Добавлено свойство setWidth
}

export const ProjectsAndFormsList: FC<ProjectsAndFormsListProps> = ({
  setIsSmall,
  handleOpenCreateProjectModal,
  handleOpenFormModal,
  search,
  setSearch,
  setWidth,
}) => {
  const { projects, isProjectsLoading } = useContext(ProjectsContext);
  const { user, darkMode } = useContext(UserContext);

  const [sidebarWidth, setSidebarWidth] = useState(320); // Default width of the sidebar.

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, 500);

  const setSmall = () => {
    setIsSmall(true);
    setSearch('');
    localStorage.setItem('sidebar', 'small');
    setSidebarWidth(200); // Минимальная ширина для "малого" режима
  };

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    const newWidth = e.clientX;

    if (newWidth > 200 && newWidth < 600) {
      setSidebarWidth(newWidth); // Обновляем ширину
    } else if (newWidth <= 200) {
      setSmall(); // Если ширина меньше минимальной, переходим в малый режим
    }
  };

  const handleDragStart = (e: React.DragEvent) => {
    e.dataTransfer.setDragImage(new Image(), 0, 0); // Убираем стандартное изображение при перетаскивании
  };

  return (
    <div
      className="relative flex flex-shrink-0 flex-col overflow-hidden bg-white-100 p-3 dark:bg-blue-gradient-100"
      id="resizable"
      style={{ width: `${sidebarWidth}px` }} // Применяем динамическую ширину
    >
      <div
        className="absolute right-0 top-0 z-10 h-full w-2 cursor-col-resize"
        draggable="true"
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDoubleClick={setSmall}
      />
      {darkMode && (
        <>
          <div className="absolute -right-32 -top-32 h-80 w-80 bg-violet-600 opacity-35 blur-7xl" />
          <div className="absolute -left-24 top-1/3 h-80 w-80 bg-violet-500 opacity-40 blur-7xl" />
          <div className="absolute -bottom-40 -right-40 h-96 w-96 bg-violet-600 opacity-30 blur-7xl" />
        </>
      )}

      <div className="relative mb-8 flex w-full items-center pt-1.5">
        <LogoIcon className="h-8 w-4" />
        <div className="ml-auto flex items-center">
          <div className="font-gothampro-400 mr-2 max-w-44 truncate text-sm dark:text-white">
            {user?.full_name || user?.email || 'Login'}
          </div>
          <div
            className={
              'flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full ' +
              'bg-violet-1900 dark:bg-violet-1700'
            }
          >
            <UserIcon className="-mt-0.5 h-4 w-4 fill-violet-1700 dark:fill-violet-1600" />
          </div>
        </div>
      </div>

      <div className="relative mb-7 flex h-10 w-full flex-shrink-0 items-center border-b border-black dark:border-white">
        <LoupeIcon className="mr-2.5 h-4 w-4 min-w-[1rem] dark:fill-white" />
        <input
          placeholder="Поиск"
          className="font-gothampro-400 w-full bg-transparent text-sm dark:text-white dark:placeholder-white"
          onChange={handleSearch}
        />
      </div>

      <div className="relative mb-5 flex w-full flex-1 flex-col overflow-auto">
        {!projects.length && isProjectsLoading ? (
          <div className="flex h-28 w-full items-center justify-center">
            <Loader classNames="w-10 h-10" />
          </div>
        ) : null}

        {projects.length && !isProjectsLoading
          ? projects.map((project, i) => (
              <ProjectListItem
                key={`project-${i}`}
                project={project}
                search={search}
                handleOpenFormModal={handleOpenFormModal}
              />
            ))
          : null}
      </div>

      {user?.isAdmin && (
        <Button
          variant="violet"
          classNames="relative flex-shrink-0 mt-auto"
          onClick={handleOpenCreateProjectModal}
        >
          Новый проект
        </Button>
      )}
    </div>
  );
};
