import { ITableContext, TablePartsType } from './types';

export const initNoticeData = {
  delete: 0,
  update: 0,
  create: 0,
};

export const initTableParts: TablePartsType = {
  loadedPages: [],
  totalPages: 0,
  totalRows: 0,
};

export const rowsLimit = 100;

export const tableContextDefaultState: ITableContext = {
  totalRows: 0,
  tableData: [],
  isLoading: false,
  isEdit: false,
  resetTable: () => {},
  setIsEdit: () => {},
  setTableData: () => {},
  resetTableData: () => {},
  addEmptyRows: () => {},
  setIsLoading: () => {},
  saveRows: () => {},
  setSort: () => {},
  setSearch: () => {},
  getBottomContent: () => {},
  getTopContent: () => {},
  addEmptyTopRows: () => {},
  // Добавляем недостающие поля:
  undo: () => {},
  canUndo: false,
};

